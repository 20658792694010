import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { SEO } from "../components/seo";

import ThemeContext from "../context/ThemeContext";

const AboutPage = ({ data }) => {
  const themeState = useContext(ThemeContext);

  const changeBackground = (e) => {
    removeBorder();
    themeState.toggleCursorBackground("#fff");
    // themeState.scaleCursor(3.5, true);
  };
  const resetCursor = (e) => {
    showBorder();
    themeState.toggleCursorBackground("transparent");
    // themeState.scaleCursor(1);
  };

  const removeBorder = () => {
    themeState.toggleCursorBorder();
  };
  const showBorder = () => {
    themeState.toggleCursorBorder("1px solid rgba(255,255,255,1)");
  };

  const about = data.prismicAbout.data;

  return (
    <section className="min-w-[250px] md:max-w-[1440px] mx-[15px] md:mx-auto mt-[20px] md:mt-[81px] md:px-[90px] flex flex-col-reverse md:flex-row">
      <SEO
        title="About - Morch"
        description="MØRCH IS A FASHION SALES AGENCY FOUNDED BY LOTTE MØRCH IN 1996 AND IS ONE OF THE LEADING FASHION AGENCIES IN SCANDINAVIA."
      />

      <div
        className="image-container md:w-[519px] md:h-[659px] md:mr-[33px] md:ml-auto mt-4 md:mt-0"
        // onMouseEnter={changeBackground} onMouseLeave={resetCursor}
      >
        <GatsbyImage
          image={about.hero_image.gatsbyImageData}
          alt={about.hero_image.alt || "About US Image"}
          className="w-full h-full backdrop"
          objectFit="cover"
        />
      </div>
      <div className="content md:w-[305px]">
        <h4 className="uppercase">{about.title.text}</h4>
        <div
          className="mt-2 md:max-w-[305px] prose"
          dangerouslySetInnerHTML={{
            __html: about.description.html,
          }}
        ></div>
      </div>
    </section>
  );
};

export default AboutPage;

export const query = graphql`
  {
    prismicAbout {
      data {
        title {
          text
        }
        description {
          html
        }
        hero_image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          alt
        }
      }
    }
  }
`;
